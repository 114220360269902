/*
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-08-25 11:24:38
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-23 14:33:42
 */
import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";

// 获取详情
export const joinDetail = data => {
  return api({
    url: "/admin/cyc_activity/ActivityUser/detail",
    method: "post",
    data
  });
};

