<template>
  <div v-loading="loading">
    <div class="list-content">
      <p class="save-title" style="margin-top: 4px">基本信息</p>

      <el-form class="save-content" label-width="120px" @submit.native.prevent>
        <el-form-item label="用户名称：">
          {{ joinDetail.nickname || '--' }}
        </el-form-item>
        <el-form-item label="会内职务：">
          {{ joinDetail.title_name || '--' }}
        </el-form-item>
        <el-form-item label="手机号码：">
          {{ joinDetail.phone || '--' }}
        </el-form-item>
        <el-form-item label="报名费用：">
          {{ joinDetail.order_price || '--' }}
        </el-form-item>
        <el-form-item label="额外费用：">
          {{ joinDetail.extra_pay_text || '--' }}
          <el-button
            v-if="
              joinDetail.extra_price_config &&
              joinDetail.extra_price_config.price
            "
              @click="showExtra(joinDetail.join_form || [])"
              type="text"
          >查看
          </el-button>
        </el-form-item>
        <el-form-item label="订单状态：">
          {{ joinDetail.order_status || '--' }}
        </el-form-item>
        <el-form-item label="数据类型：">
          {{ joinDetail.sign_type_text || '--' }}
        </el-form-item>
      </el-form>
      <p
          class="save-title"
          style="margin-top: 4px"
          v-if="joinDetail.join_form && joinDetail.join_form.length"
      >
        其他信息
      </p>
      <el-form class="save-content" :label-width="joinFormWidth">
        <template v-for="item in joinDetail.join_form">
          <template v-if="item.field === 'base-title'">
            <h3 class="section-title" :key="item.timestamp">
              {{ item.props && item.props.normal.value }}
            </h3>
          </template>
          <!-- 地址选择 -->
          <el-form-item
              :label="`${
              item.name || item.title || (item.props && item.props.normal.label)
            }： `"
              v-else-if="
              [
                'work_address',
                'home_address',
                'soho_address',
                'base-address-picker',
              ].includes(item.field)
            "
          >
            <template v-if="item.user_data">
              <template v-if="item.user_data.address_name">
                （{{ item.user_data.address_name }}）
              </template>
              <template v-if="item.user_data.name">
                （{{ item.user_data.name }}）
              </template>
              {{ item.user_data.address }}
              {{ item.user_data.work_address }}
              {{ item.user_data.home_address }}
              {{ item.user_data.soho_address }}
            </template>
            <!-- zoom 越大，视野越小 -->
            <baidu-map
                :center="{
                lat:
                  item.user_data.work_address_lat ||
                  item.user_data.soho_address_lat ||
                  item.user_data.home_address_lat ||
                  item.user_data.lat ||
                  39.910924,
                lng:
                  item.user_data.work_address_lng ||
                  item.user_data.soho_address_lng ||
                  item.user_data.home_address_lng ||
                  item.user_data.lng ||
                  116.413387,
              }"
                :scroll-wheel-zoom="true"
                :zoom="25"
                class="bmView"
                v-if="
                item.user_data &&
                (item.user_data.work_address_lat ||
                  item.user_data.soho_address_lat ||
                  item.user_data.home_address_lat ||
                  item.user_data.lat)
              "
            >
              <!-- 如果需要动画，加上： animation="BMAP_ANIMATION_BOUNCE" -->
              <bm-marker
                  :dragging="false"
                  :position="{
                  lng:
                    item.user_data.work_address_lng ||
                    item.user_data.soho_address_lng ||
                    item.user_data.home_address_lng ||
                    item.user_data.lng ||
                    116.404,
                  lat:
                    item.user_data.work_address_lat ||
                    item.user_data.soho_address_lat ||
                    item.user_data.home_address_lat ||
                    item.user_data.lat ||
                    39.915,
                }"
              >
                <bm-label
                    :labelStyle="{ color: 'red', fontSize: '24px' }"
                    :offset="{ width: -35, height: 30 }"
                    content=""
                />
              </bm-marker>
            </baidu-map>
            <span v-else>用户未选择</span>
          </el-form-item>
          <!--
            :label-width="(item.name || item.title || (item.props && item.props.normal.label)).length > 7 ? 'auto' : joinFormWidth"
          -->
          <el-form-item
              :key="item.name"
              :label="`${
              item.name || item.title || (item.props && item.props.normal.label)
            }： `"
              v-else
          >
            <template
                v-if="
                item.type === 'select' &&
                item.options &&
                item.options.length &&
                item.options.find((op) => op.text === item.text_value)
              "
            >
              {{ item.text_value }}
              <template
                  v-if="item.options.find((op) => op.text === item.text_value)"
              >
                ({{
                item.options.find((op) => op.text === item.text_value)
                .pay_type
                ? ''
                : '￥'
                }}
                {{
                item.options.find((op) => op.text === item.text_value)
                .pay_amount
                }}
                {{
                item.options.find((op) => op.text === item.text_value)
                .pay_type
                ? '积分'
                : ''
                }})
              </template>
            </template>
            <template v-else-if="item.type === 'multi-select'">
              <span v-for="ele in item.text_value" :key="ele">
                {{ ele }}
                <!-- 如果用户填的和备选项不重合，就是选项被删了可能 -->
                <template v-if="item.options.find((op) => op.text === ele)">
                  ({{
                    item.options.find((op) => op.text === ele).pay_type
                      ? ''
                      : '￥'
                  }}
                  {{ item.options.find((op) => op.text === ele).pay_amount }}
                  {{
                    item.options.find((op) => op.text === ele).pay_type
                      ? '积分'
                      : ''
                  }})
                </template>
                <br/>
              </span>
              <span v-if="!item.text_value || !item.text_value.length">{{
                '' || '用户未选择'
              }}</span>
            </template>
            <template v-else-if="item.field === 'avatar'">
              <el-avatar :src="item.text_value" @click.native="$previewImage([item.text_value])"
                         class="cursor-pointer"></el-avatar>
            </template>
            <template v-else-if="item.field === 'soho_address'">
              {{ item.text_value.soho_address || '用户未选择' }}
            </template>
            <template v-else-if="item.field === 'id_card_image'">
              <template
                  v-if="
                  item.text_value &&
                  !item.text_value.contrary &&
                  !item.text_value.positive
                "
              >{{ '用户未上传' }}
              </template
              >
              <div v-else class="flex">
                <div class="positive flex-col flex-justify-center">
                  <el-image
                      :preview-src-list="
                      [
                        item.text_value.positive,
                        item.text_value.contrary,
                      ].filter((el) => !!el)
                    "
                      :src="item.text_value.positive"
                      class="flex-1"
                      fit="cover"
                      style="width: 180px"
                      v-if="item.text_value.positive"
                  />
                  <el-image v-else class="flex-1" style="width: 180px">
                    <template #error
                    >
                      <div class="el-image__error">用户未上传</div>
                    </template
                    >
                  </el-image>
                  <span class="flex-justify-center">正面</span>
                </div>
                <div class="contrary flex-col flex-justify-center" style="">
                  <el-image
                      :preview-src-list="
                      [
                        item.text_value.positive,
                        item.text_value.contrary,
                      ].filter((el) => !!el)
                    "
                      :src="item.text_value.contrary"
                      class="flex-1"
                      fit="cover"
                      style="width: 180px"
                  />
                  <span class="flex-justify-center">人像面</span>
                </div>
              </div>
            </template>
            <template v-else-if="item.field === 'birthday'">
              <span v-if="item.text_value && item.text_value.birthday">
                {{ item.text_value.birthday }}（{{
                  dateTypeOption.find(
                    (d) => d.id === item.text_value.birthday_type || 0
                  ).label
                }}）
              </span>
              <span v-else>{{ '用户未填写' }}</span>
            </template>
            <template
                v-else-if="
                item.field === 'base-image-picker' ||
                item.field === 'base-file-picker'
              "
            >
              <div
                  :class="[
                  item.field === 'base-file-picker' ? 'flex-col' : 'flex-wrap',
                ]"
                  class="flex"
                  v-if="item.text_value && item.text_value.length"
              >
                <ol
                    style="padding-inline-start: 20px; margin: 0"
                    v-if="item.field === 'base-file-picker'"
                >
                  <li v-for="it in item.text_value" :key="it.url">
                    <!-- 不同源的 download 设置不会生效哦 -->
                    <a :download="it.title" :href="it.url" class="file-link" target="_blank" v-if="it.url.includes('.pdf?') || it.url.endsWith('.pdf')">{{
                      it.title || it.url
                      }}</a>
                    <a @click="customDownload(it)" class="file-link" href="#" type="text" v-else>{{it.title || it.url}}</a>
                  </li>
                </ol>
                <div
                    style="
                    width: 100%;
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 180px);
                    grid-gap: 8px;
                  "
                    v-else
                >
                  <template v-for="it in item.text_value">
                    <el-image
                        :key="it.url"
                        :preview-src-list="item.text_value.map((itt) => itt.url)"
                        :src="it.url"
                        fit="cover"
                        style="width: 180px"
                        v-if="it.type !== 'file' && it.url !== undefined"
                    />
                    <el-image
                        :key="it"
                        :preview-src-list="item.text_value"
                        :src="it"
                        fit="cover"
                        style="width: 180px"
                        v-else-if="it.type !== 'file'"
                    />
                  </template>
                </div>
              </div>
              <span v-else>{{ '用户未上传' }}</span>
            </template>
            <template v-else-if="item.field === 'base-input'">
              <template v-if="typeof item.text_value !== 'string'">
                <!-- 数据格式错了 -->
                --
              </template>
              <template v-else>
                {{
                item.text_value ||
                (item.type === 'input' ? '用户未填写' : '用户未选择')
                }}
              </template>
            </template>
            <template v-else-if="item.field === 'school_idcard'">
              <el-image
                  :preview-src-list="[item.text_value]"
                  :src="item.text_value"
                  fit="cover"
                  style="width: 180px"
                  v-if="item.text_value"
              />
              <span v-else>{{ '用户未上传' }}</span>
            </template>
            <template v-else>
              {{
              item.text_value ||
              (item.type === 'input' || item.type === undefined
              ? '用户未填写'
              : '用户未选择')
              }}
            </template>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <extra-dialog :extra-dialog="extraDialog"/>
    <fixed-action-bar>
      <el-button size="medium" @click="$router.back()">返回</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall'
import {joinDetail} from '../../api/sign-up-list/detail'
import ExtraDialog from '@/modules/activity/components/ActivityList/SignUpList/ExtraDialog'

export default {
  components: {ExtraDialog, FixedActionBar, SingleMediaWall},
  data() {
    return {
      loading: false,
      // 详情
      joinDetail: {},
      // 数据Id
      jid: 0,
      extraDialog: {
        show: false,
        data: [],
      },
      dateTypeOption: [
        {label: '农历', id: 0},
        {label: '新历', id: 1},
      ],
    }
  },
  watch: {},
  created() {
    // 数据id
    this.jid = this.$route.params.jid
    this.getDatail()
  },
  computed: {
    joinFormWidth() {
      if (this.joinDetail.join_form && this.joinDetail.join_form.length) {
        const text = this.joinDetail.join_form.reduce((a, b) => {
          if (b.field !== 'base-title' && b.name?.length > a.length) {
            return b.name
          } else {
            return a
          }
        }, '')
        const dummyElement = document.createElement('span')
        dummyElement.style.visibility = 'hidden'
        dummyElement.style.position = 'absolute'
        dummyElement.style.whiteSpace = 'nowrap'
        dummyElement.innerText = text
        document.body.appendChild(dummyElement)
        let textWidth = +getComputedStyle(dummyElement).width?.replace(
            /px/g,
            ''
        )
        if (textWidth < 120) {
          textWidth = 120
        } else {
          textWidth = 10 + textWidth
        }
        if (textWidth > 300) {
          textWidth = 300
        }
        document.body.removeChild(dummyElement)
        return textWidth + 'px'
      } else {
        return '120px'
      }
    },
  },
  methods: {
    customDownload(item) {
      this.downloadFile(item)
    },
    downloadFile(file) {
      fetch(file.url)
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(err => console.error('Error downloading file:', err));
    },
    showExtra(formList) {
      this.extraDialog.data = formList
      this.extraDialog.show = true
    },
    getDatail() {
      this.loading = true
      joinDetail({id: this.jid})
          .then((res) => {
            this.joinDetail = res.data
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
          })
    },
  },
}
</script>

<style lang="scss" scoped>
.save-title {
  padding: 0 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  border-left: 3px #3479ef solid;
  line-height: 16px;
  margin-bottom: 19px;
}

.section-title {
  /*display: inline-block;*/
  font-size: 16px;
  /*min-width:120px;*/
  /*text-align: right;*/
  /*padding-right: 12px;*/
  margin-bottom: 22px;
}

.file-link {
  display: block;
  word-break: break-word;
  text-decoration: underline;
}

.save-content {
  padding: 12px 4px 10px;

  .el-form-item__label,
  ::v-deep.el-form-item__label {
    /*white-space: nowrap;*/
    white-space: normal;
  }

  .el-input {
    max-width: 295px;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.positive + .contrary {
  margin-left: $space;
}

.bmView {
  width: 610px;
  /*width: 100%;*/
  height: 350px;
  /*margin: 0 auto;*/
}
</style>
